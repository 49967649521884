<div class = "ui-user-selector-container" [ngClass] = "{
    'ui-user-selector-selected': selectedUser
}" [mat-menu-trigger-for] = "userMenu">
    <div class = "ui-user-photo-container">
        <div class = "ui-user-photo">
            <span *ngIf = "!selectedUser" class = "fa-regular fa-user"></span>
            <img *ngIf = "selectedUser" [src] = "selectedUser.detail.profilePhoto" alt = "Imagem do usuário">
        </div>
    </div>
    <div *ngIf = "selectedUser" class = "ui-user-detail-container">
        <div class = "ui-title">
            {{ selectedUser.name }}
        </div>
        <div class = "ui-sub-title">
            {{ selectedUser.nationalRegister | mask: selectedUser.nationalRegister.length ? '000.000.000-00' : '99.999.999/9999-99'}}
        </div>
        <div class = "ui-sub-title">
            {{ description }}
        </div>
    </div>
    <div class = "ui-user-empty" *ngIf = "!selectedUser" type = "button">
        <span>Selecione Usuário</span>
        <span>{{ description }}</span>
    </div>

    <mat-menu #userMenu = "matMenu" class = "ui-menu">
        <div class = "ui-menu-container">
            <div class = "ui-users-menu-header-container">
                <span class = "ui-title">Usuários</span>
                <mat-form-field class = "ui-form-field ui-field-search ui-form-field-small" (click) = "eventInputClick($event)">
                    <input matInput type = "text" (input) = "eventUserFilterChange($event)">
                    <mat-label>Filtrar Usuário</mat-label>
                    <div class = "ui-spinner-menu-container">
                        <app-spinner *ngIf = "searching" width = "25px" height = "25px" type = "full"></app-spinner>
                    </div>
                </mat-form-field>
            </div>
            <div class = "ui-users-menu-container">
                <button [disabled] = "searching" class = "ui-user-menu-container" [ngClass] = "{ 'ui-user-menu-selected': user._id === selectedUser?._id }" *ngFor = "let user of users; let index = index" (click) = "eventUserSelected(index, user)">
                    <div class = "ui-user-photo" >
                        <img [src] = "user.detail.profilePhoto" alt = "Imagem do usuário">
                    </div>
                    <div class = "ui-user-menu-information">
                        <div class = "ui-title">
                            {{ user.name }}
                        </div>
                        <div class = "ui-sub-title">
                            {{ user.nationalRegister }}
                        </div>
                    </div>
                </button>
                <div class = "ui-empty-container" [style.opacity]= "searching ? 0.3 : 1" *ngIf = "users?.length === 0">
                    Nenhum Usuário Encontrado
                </div>
                <app-spinner type = "full" width = "25px" height = "25px" *ngIf = "!users"></app-spinner>
            </div>
        </div>
    </mat-menu>
</div>

<div class = "ui-copilot-icon" (click) = "eventAIEnable()">
</div>
<div class = "ui-copilot-container" [style.display] = "isEnabled ? 'flex': 'none'">
    <div class = "ui-ciera-icon">
        <img src = "./assets/favicon.svg">
    </div>

    <div class = "ui-copilot">
        <div class="visualizer-container">
            <canvas #waveCanvas></canvas>
            <audio #audio style = "display: none"></audio>
        </div>
        <div class = "ui-messages">
            <div class = "ui-message-container" *ngFor = "let message of messages">
                <div class = "ui-message">
                    <span class = "ui-gradient-text">{{ message.ask }}</span>
                </div>
                <div class = "ui-message-response" *ngIf = "message.audioResponseURL" >
                    <span class = "ui-icon fa-solid fa-headphones"></span>
                    <a class = "ui-text-button" (click) = "eventPlaySound(message.audioResponseURL)">Ouvir</a>
                </div>
            </div>
        </div>
        <mat-form-field class = "ui-copilot-sender ui-form-field ui-field-search ui-form-field-full ui-form-field-small">
            <input #input placeholder = "No que posso ser útil?" matInput type = "text" (keyup) = "$event.key === 'Enter' ? eventSend() : null">
            <button mat-button class = "ui-circle-button" (click) = "eventSend()">
                <span class = "ui-icon fa-solid fa-arrow-right"></span>
            </button>
        </mat-form-field>
    </div>
    <div class = "ui-copilot-options">
        <button mat-button class = "ui-button" (click) = "isEnabled = false">
            Fechar
        </button>
    </div>
 </div>


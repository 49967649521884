import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
    template: ''
})
export abstract class AbstractComponent implements OnDestroy {
    @HostBinding('class') classes = 'ui-themed-container ui-component-container';

    public subscriptions$: Subscription = new Subscription();

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}

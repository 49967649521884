import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../shared/abstract/abstract.component';
import { mainStates, managementStates } from './app-states.const';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StateModel } from '../shared/state/state.model';
import { SystemService } from '../core/system/system.service';
import { SignService } from '../sign/sign.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent extends AbstractComponent implements OnInit {
    states: {
        all: Array<StateModel>;
        main: Array<StateModel>;
        management: Array<StateModel>;
    } = {
        all: [...mainStates, ...managementStates],
        main: mainStates,
        management: managementStates
    };

    actualState: StateModel | undefined;

    constructor(
        protected router: Router,
        protected systemService: SystemService,
        protected signService: SignService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.watchRoutes();
    }

    public eventSignOut(): void {
        const sessionId = this.systemService.getLocalStorage('sessionId') as string;
        this.subscriptions$.add(this.signService.signOut(sessionId).subscribe(() => {
            this.systemService.signOut();
        }));
    }
    protected setState(statePath: string): void {
        this.actualState = this.states.all.find((state) => state.path === statePath);
    }
    protected getUserPhoto(): string {
        return this.systemService.getUser().profilePhoto;
    }
    protected watchRoutes(): void {
        if (this.router.url !== '') {
            this.setState(this.router.url.replace('/', ''));
        }
        this.subscriptions$.add(
            this.router.events.pipe(filter((event) => {
                return event instanceof NavigationEnd;
            })).subscribe((event) => {
                const statePath = (event as NavigationEnd).urlAfterRedirects.replace('/', '');
                this.setState(statePath);
            })
        );
    }
}

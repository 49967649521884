import { Pipe, PipeTransform } from '@angular/core';
import { StatusEnum } from '../enum/status.enum';
import { UserStatusEnum } from '../../user/enum/user-status.enum';

@Pipe({name: 'StatusCssClass'})
export class StatusCssClass implements PipeTransform {
    public transform(status: StatusEnum | UserStatusEnum): string {
        if (status === StatusEnum.COMPLETED || status === UserStatusEnum.ACTIVE) {
            return 'ui-icon-success';
        } else if (status === StatusEnum.EXECUTING) {
            return 'ui-icon-activated';
        } else {
            return 'ui-icon-regular';
        }
    }
}

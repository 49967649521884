import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopilotComponent } from './copilot.component';
import { SharedModule } from '../../shared/component/shared.module';

@NgModule({
    declarations: [
        CopilotComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        CopilotComponent
    ]
})
export class CopilotModule { }

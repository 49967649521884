import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../../../module/user/user.service';
import { Subscription } from 'rxjs';
import { PaginatedModel } from '../pagination/model/paginated.model';
import { UserModel } from '../../../module/user/model/user.model';
import { AbstractComponent } from '../../definition/abstract/abstract.component';

@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    styleUrl: './user-select.component.scss'
})
export class UserSelectComponent extends AbstractComponent implements OnInit, OnChanges {
    @Input() description: string = '';
    @Input() disabled: boolean = false;
    @Input() selectedUserId: string = '';

    @Output() userSelected = new EventEmitter<string | undefined>();

    userFilterValue: string = '';
    users: Array<UserModel>;
    request: Subscription = new Subscription();
    searching = true;
    selectedUser: UserModel | undefined;

    constructor(
        protected userService: UserService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.searchUsers();
    }
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedUserId'] && this.users) {
            this.selectedUser = this.users.find((user) => user._id === this.selectedUserId);
        }
    }

    public eventInputClick(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
    }
    public eventUserSelected(index: number, user: UserModel): void {
        if (this.selectedUser && user._id === this.selectedUser._id) {
            this.selectedUser = undefined;
        } else {
            this.selectedUser = this.users[index];
        }

        this.userSelected.emit(this.selectedUser?._id);
    }
    public eventUserFilterChange(value: Event): void {
        this.userFilterValue = (value.target as HTMLInputElement).value;
        this.searchUsers();
    }
    protected handleUsers(users: PaginatedModel<UserModel>): void {
        this.users = users.items;

        if (this.selectedUserId) {
            this.selectedUser = this.users.find((user) => user._id === this.selectedUserId);
        }
    }
    protected searchUsers(): void {
        this.request.unsubscribe();
        this.searching = true;
        this.request = this.userService.getUsers(this.userFilterValue).subscribe((users) => {
            this.searching = false;
            this.handleUsers(users);
        });
    }
}

<span [ngClass] = "{
    'ui-icon-success': !data.isError && !data.isConfirmation,
    'ui-icon-danger': data.isError,
    'ui-icon-warning': data.isConfirmation
}" [class] = "'ui-message-icon ' + data.icon"></span>
<span class = "ui-bold-message">{{ data.boldMessage }}</span>
<span class = "ui-message">{{ data.message }}</span>
<span *ngIf = "data.subMessage" class = "ui-sub-message">{{ data.subMessage }}</span>
<button *ngIf = "data.link !== undefined" class = "ui-text-button ui-button-link" (click) = "eventFollowLink()">{{ data.linkMessage }}</button>
<div class = "ui-dialog-actions" *ngIf = "!data.isConfirmation">
    <button matTooltip = "Tentar Novamente" class = "ui-circle-button" *ngIf = "data.canRefresh" (click) = "eventRefresh()">
        <span class = "ui-icon ui-danger-icon fa-solid fa-refresh"></span>
    </button>
</div>
<div class = "ui-dialog-actions" *ngIf = "data.isConfirmation">
    <button mat-button class = "ui-text-button" (click) = "eventConfirmation(true)">
        Sim
    </button>
    <button mat-button class = "ui-text-button" (click) = "eventConfirmation(false)">
        Não
    </button>
</div>

import { StateModel } from '../../../shared/definition/model/state.model';

export const mainStates: Array<StateModel> = [
    {
        path: 'health-analysis',
        name: 'Análisis de Saúde',
        base: 'health-analysis',
        icon: 'fa-solid fa-microscope',
        roles: ['ciera-technical'],
        fontSize: '19px'
    }, {
        path: 'workflow-instance',
        base: 'workflow-instance',
        roles: [''],
        name: 'Análises',
        icon: 'fa-solid fa-layer-group',
        fontSize: '16px'
    }, {
        path: 'exam',
        base: 'exam',
        roles: [''],
        name: 'Exames',
        icon: 'fa-solid fa-flask-vial'
    }
];

export const managementStates: Array<StateModel> = [
    {
        icon: 'fa-solid fa-user-gear',
        name: 'Gestão Usuários',
        path: 'iam/management/user',
        base: 'iam/management/user',
        fontSize: '18px',
        roles: ['ciera-technical']
    }
]

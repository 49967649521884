import { Component, Input } from '@angular/core';
import { BadgeTypeEnum } from '../badge/enum/badge-type.enum';

@Component({
    selector: 'app-attribute-viewer',
    templateUrl: './attribute-viewer.component.html',
    styleUrls: ['./attribute-viewer.component.scss']
})
export class AttributeViewerComponent {
    @Input() label: string;
    @Input() value: string | null | undefined;
    @Input() haveIcon: boolean = true;
}

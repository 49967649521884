<div class = "ui-attribute-viewer-icon" *ngIf = "haveIcon">
    <ng-content></ng-content>
</div>
<div class = "ui-attribute-viewer-container">
    <div class = "ui-attribute-viewer-label">
        {{ label }}
    </div>
    <div class = "ui-attribute-viewer-value">
        {{ value }}
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CopilotService {
    BASE = environment.COPILOT;
    constructor(
        protected httpService: HttpClient
    ) { }

    public talk(context: {message: string; filePath?: string }): Observable<Blob> {
        return this.httpService.post(`${this.BASE}/ai/copilot`, context, { responseType: 'blob' });
    }
}

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CopilotService } from './copilot.service';
import { AbstractComponent } from '../../shared/definition/abstract/abstract.component';
import { MessageModel } from './model/message.model';

@Component({
  selector: 'app-copilot',
  templateUrl: './copilot.component.html',
  styleUrl: './copilot.component.scss'
})
export class CopilotComponent extends AbstractComponent implements AfterViewInit {
    isEnabled = false;
    messages: Array<MessageModel> = [];

    constructor(
        protected copilotService: CopilotService
    ) {
        super()
    }

    public eventAIEnable(): void {
        this.isEnabled = true;
    }
    public eventPlaySound(audioUrl: string): void {
        this.audioElement.nativeElement.src = audioUrl;
        this.audioElement.nativeElement.play();

        this.setupAudioVisualizer();
        this.startVisualizer()
    }
    public eventSend(): void {
        const message = {
            ask: this.input.nativeElement.value
        } as MessageModel ;
        this.messages.push(message);
        this.input.nativeElement.value = '';
        this.copilotService.talk({ message: message.ask }).subscribe((blob) => {
            message['audioResponseURL'] = URL.createObjectURL(blob);
            this.eventPlaySound(message['audioResponseURL'] as string);
        });
    }

    @ViewChild('input', { static: false }) input!: ElementRef<HTMLInputElement>;
    @ViewChild('waveCanvas', { static: false }) waveCanvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild('audio', { static: false }) audioElement!: ElementRef<HTMLAudioElement>;

    private ctx!: CanvasRenderingContext2D;
    private audioContext!: AudioContext;
    private analyser!: AnalyserNode;
    private dataArray!: Uint8Array;
    private animationId!: number;

    ngAfterViewInit() {
        this.ctx = this.waveCanvas.nativeElement.getContext('2d')!;
        this.drawStaticCircle();

    }

    drawStaticCircle() {
        const canvas = this.waveCanvas.nativeElement;
        const ctx = this.ctx;
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 40;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        ctx.strokeStyle = 'rgba(0, 150, 255, 0.8)';
        ctx.lineWidth = 4;
        ctx.stroke();
    }

    setupAudioVisualizer() {
        if (!this.audioContext) {
            this.audioContext = new AudioContext();
        }

        const source = this.audioContext.createMediaElementSource(this.audioElement.nativeElement);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 64;
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        source.connect(this.analyser);
        this.analyser.connect(this.audioContext.destination);
    }

    startVisualizer() {
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }
        this.drawWave();
    }

    drawWave() {
        this.animationId = requestAnimationFrame(() => this.drawWave());
        this.analyser.getByteFrequencyData(this.dataArray);

        const canvas = this.waveCanvas.nativeElement;
        const ctx = this.ctx;
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 40;
        const maxRadius = 70;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        ctx.strokeStyle = 'rgba(0, 150, 255, 0.8)';
        ctx.lineWidth = 4;
        ctx.stroke();

        for (let i = 0; i < this.dataArray.length; i++) {
            const angle = (i / this.dataArray.length) * Math.PI * 2;
            const barHeight = (this.dataArray[i] / 255) * (maxRadius - radius);
            const x1 = centerX + Math.cos(angle) * radius;
            const y1 = centerY + Math.sin(angle) * radius;
            const x2 = centerX + Math.cos(angle) * (radius + barHeight);
            const y2 = centerY + Math.sin(angle) * (radius + barHeight);

            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.strokeStyle = `hsl(${i * 10}, 100%, 50%)`;
            ctx.lineWidth = 2;
            ctx.stroke();
        }
    }
}

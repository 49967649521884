import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserModel } from '../sign/model/user.model';
import { PaginatedModel } from '../shared/pagination/model/paginated.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    BASE = environment.USER;
    constructor(
        protected httpService: HttpClient
    ) { }

    public createUser(user: Partial<UserModel>): Observable<UserModel> {
        return this.httpService.post<UserModel>(`${this.BASE}/user`, user);
    }
    public updateUser(userId: string, user: Partial<UserModel>): Observable<UserModel> {
        return this.httpService.patch<UserModel>(`${this.BASE}/user/${userId}`, user);
    }
    public deleteUser(userId: string): Observable<void> {
        return this.httpService.delete<void>(`${this.BASE}/user/${userId}`);
    }
    public getUsers(filterValue?: string, page = 1, limit = 10): Observable<PaginatedModel<UserModel>> {
        let queryParams = '';
        if (filterValue) {
            queryParams = `?filterValue=${filterValue}`;
        }
        queryParams += `${queryParams ? '&' : '?'}limit=${limit}&page=${page}`;
        return this.httpService.get<PaginatedModel<UserModel>>(`${this.BASE}/user${queryParams}`);
    }
}

import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { SpinnerModule } from './spinner/spinner.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { SizeFormat } from './pipe/size-format';
import { BadgeModule } from './badge/badge.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { VolumeFormat } from './pipe/volume-format';
import { FilterModule } from './filter/filter.module';
import { StatusCssClass } from './pipe/status-css-class';
import { StatusFormat } from './pipe/status-format';
import { UserStatusFormat } from './pipe/enum-format';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [
        MessageDialogComponent,
        SizeFormat,
        VolumeFormat,
        StatusCssClass,
        StatusFormat,
        UserStatusFormat
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        SpinnerModule,
        ReactiveFormsModule,
        MatTableModule,
        BadgeModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTabsModule
    ], exports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        SpinnerModule,
        ReactiveFormsModule,
        MessageDialogComponent,
        MatAutocompleteModule,
        MatTableModule,
        SizeFormat,
        BadgeModule,
        MatSelectModule,
        VolumeFormat,
        StatusCssClass,
        StatusFormat,
        UserStatusFormat,
        MatTabsModule
    ]
})
export class SharedModule { }

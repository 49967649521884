import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardService } from './core/guard/auth-guard.service';

const routes: Routes = [{
    path: '',
    redirectTo: 'sample',
    pathMatch: 'full'
}, {
    path: 'sign/in',
    loadChildren: () => import('./sign/sign.module').then(module => module.SignModule),
}, {
    path: 'sample',
    loadChildren: () => import('./sample/sample.module').then(module => module.SampleModule),
    canActivate: [ authGuardService ]
}, {
    path: 'exam',
    loadChildren: () => import('./exam/exam.module').then(module => module.ExamModule),
    canActivate: [ authGuardService ]
}, {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(module => module.UserModule),
    canActivate: [ authGuardService ]
}, {
    path: 'workflow-instance',
    loadChildren: () => import('./workflow-instance/workflow-instance.module').then(module => module.WorkflowInstanceModule),
    canActivate: [ authGuardService ]
}
    /*{
        path: 'workflow-definition',
        loadChildren: () => import('./workflow/workflow-definition.module').then(module => module.WorkflowDefinitionModule),
        canActivate: [ authGuardService ]
    }*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import { StateModel } from '../shared/state/state.model';

export const mainStates: Array<StateModel> = [
    {
        path: 'sample',
        name: 'Amostras',
        base: 'sample',
        icon: 'fa-solid fa-microscope',
        fontSize: '20px'
    }, {
        path: 'workflow-instance',
        base: 'workflow-instance',
        name: 'Análises',
        icon: 'fa-solid fa-layer-group',
        fontSize: '17px'
    }, {
        path: 'exam',
        base: 'exam',
        name: 'Exames',
        icon: 'fa-solid fa-flask-vial'
    }
];

export const managementStates: Array<StateModel> = [
    {
        path: 'user/management',
        base: 'user',
        name: 'Gestão Usuários',
        icon: 'fa-solid fa-user-gear',
        fontSize: '18px'
    }
]

import { Pipe, PipeTransform } from '@angular/core';
import { UserStatusEnum } from '../../user/enum/user-status.enum';

@Pipe({name: 'UserStatusFormat'})
export class UserStatusFormat implements PipeTransform {
    public transform(status: UserStatusEnum): string {
        if (status === UserStatusEnum.ACTIVE) {
            return 'Ativo';
        } else if (status === UserStatusEnum.INACTIVE) {
            return 'Inativo';
        } else {
            return 'Bloqueado'
        }
    }
}
